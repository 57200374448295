
	frappe.templates['apps_switcher'] = `<a class="app-switcher-dropdown" style="text-decoration: none;">
    <div class="standard-sidebar-item">
        <div class="d-flex">
            <div class="sidebar-item-icon app-logo-container">
                <img class="app-logo"
                    src="{%= app_logo_url %}" alt="{%= ("App Logo") %}">
            </div>
            <div class="sidebar-item-label app-title" style="margin-left: 10px; margin-top: 1px">
                {%= app_title %}
            </div>
        </div>
        <div class="sidebar-item-control">
            <button class="btn-reset drop-icon show-in-edit-mode">
                <svg class="es-icon es-line  icon-sm" style="display: block;margin:auto;" aria-hidden="true">
                    <use class="" href="#es-line-down"></use>
                </svg>
            </button>
        </div>
    </div>
</a>

<div class="app-switcher-menu hidden" role="menu">
</div>`;
