import { withModifiers as _withModifiers, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0923e05b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search-box" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "combo-box-items" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["Combobox"], {
    modelValue: $setup.selectedValue,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.selectedValue) = $event)),
    nullable: ""
  }, {
    default: _withCtx(() => [
      _createVNode($setup["ComboboxOptions"], {
        class: "combo-box-options",
        static: ""
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode($setup["ComboboxInput"], {
              ref: "search",
              class: "search-input form-control",
              type: "text",
              onChange: _cache[0] || (_cache[0] = (e) => ($setup.query = e.target.value)),
              value: $setup.query,
              placeholder: _ctx.__($setup.props.placeholder),
              autocomplete: "off",
              onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
            }, null, 8 /* PROPS */, ["value", "placeholder"]),
            _createElementVNode("button", {
              class: "clear-button btn btn-sm",
              onClick: $setup.clear_search
            }, [
              _createElementVNode("div", {
                innerHTML: _ctx.frappe.utils.icon('close', 'sm')
              }, null, 8 /* PROPS */, _hoisted_2)
            ])
          ]),
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.sortedOptions, (field, i) => {
              return (_openBlock(), _createBlock($setup["ComboboxOption"], {
                as: "template",
                key: i,
                value: field
              }, {
                default: _withCtx(({ active }) => [
                  _createElementVNode("li", {
                    class: _normalizeClass(['combo-box-option', active ? 'active' : ''])
                  }, _toDisplayString(_ctx.__(field.label)), 3 /* TEXT, CLASS */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"]))
            }), 128 /* KEYED_FRAGMENT */))
          ])
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]))
}